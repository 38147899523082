import { Query } from 'react-apollo';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { metaDescription, metaKeywords } from 'constants/meta';
import saleQuery from 'graphql/queries/sale.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import Label from 'components/atoms/Label';
import TextFormatter from 'components/atoms/TextFormatter';
import MainTemplate from 'components/templates/MainTemplate';
import ItemsList from 'components/molecules/ItemsList';
import Breadcrumbs from 'components/molecules/Breadcrumbs';

const FirstRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0.3rem -0.2rem 0;
`;

const Title = styled.h1`
  margin: 0 0.2rem 0.2rem;
  font-weight: normal;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
  margin: 0.5rem 0.2rem 0;
  display: block;
`;

const StyledLabel = styled(Label)`
  margin: 0.2rem;
`;

const ItemsWrapper = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
`;

const ItemsTitle = styled.h2`
  font-size: 1rem;
`;

const SalePage = ({ match }) => (
  <Query
    query={saleQuery}
    variables={{
      id: match.params.id,
    }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      if (!data.sale) {
        return <NotFoundPage />;
      }

      const { sale } = data;

      const now = dayjs();
      const startsAt = sale.startsAt && dayjs(sale.startsAt);
      const endsAt = sale.endsAt && dayjs(sale.endsAt);
      const isActive = (!endsAt || now.isBefore(endsAt)) && (!startsAt || now.isAfter(startsAt));

      const timeRangeText = `${startsAt && `с ${startsAt.format('DD MMM YYYY')}${endsAt && ' '}`}${
        endsAt && `по ${endsAt.format('DD MMM YYYY')}`
      }`;

      return (
        <MainTemplate>
          <Helmet>
            <title>{sale.name}</title>
            <meta name="keywords" content={metaKeywords.sales} />
            <meta
              name="description"
              content={metaDescription.sale({ timeRangeText, name: sale.name })}
            />
          </Helmet>
          <Breadcrumbs
            links={[
              {
                link: '/sales',
                text: 'Акции',
              },
            ]}
          />
          <FirstRow>
            <div>
              <Title>{sale.name}</Title>
              <Subtitle>{timeRangeText}</Subtitle>
            </div>
            {isActive && endsAt && (
              <StyledLabel color="accent1">
                дней до конца акции: {endsAt.diff(now, 'day')}
              </StyledLabel>
            )}
            {!isActive && now.isBefore(startsAt) && (
              <StyledLabel color="primary">Скоро!</StyledLabel>
            )}
            {!isActive && now.isAfter(endsAt) && (
              <StyledLabel color="neutral4">Акция завершена</StyledLabel>
            )}
          </FirstRow>
          {sale.description && <TextFormatter text={sale.description} />}
          {sale.items.length > 0 && (
            <ItemsWrapper>
              <ItemsTitle>Товары участвующие в акции</ItemsTitle>
              <ItemsList cols={[2, 3, 4]} items={sale.items.map((item) => item.item)} />
            </ItemsWrapper>
          )}
        </MainTemplate>
      );
    }}
  </Query>
);

export default SalePage;
